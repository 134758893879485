<template>
  <div>
    <div class='py-2 lg:pb-8 relative'>
      <content-background-cover background-color='#001020' class='z-0' :top-offset='0' />
      <div class='pb-4 flex flex-row justify-between items-center'>
        <button @click='goBack'
          class='pl-1 pr-2 hover:pl-0 rounded text-white border border-transparent hover:border-gray-200 flex flex-row justify-center items-center text-normal'>
          <arrow-narrow-left-icon class='h-4' />
          Back
        </button>
        <div v-if='hasPosterAndAbstractFile'
          class='text-white flex flex-row justify-center items-center gap-x-4'>
          <button @click='showContent = "poster"' :class='selectedTabClass("poster")'>poster</button>
          <button @click='showContent = "abstract"' :class='selectedTabClass("abstract")'>abstract</button>
        </div>
        <poster-audio-player v-if='hasAudioFile' />
      </div>
      <PDF-content v-show='isPosterTypePdf && showPosterContent' />
      <vod-content-vimeo v-if='!isPosterTypePdf' 
        class='w-full lg:w-1/2 mx-auto' 
        :showing-content='selectedPoster'
        :autoplay='false' 
        :mute='false'
        :default-volume='defaultPosterVolume' />
      <poster-abstract-content v-show='hasAbstractFile && showAbstractContent' />
    </div>
    <div class='mt-8 pb-64 px-2 lg:px-0'>
      <div class='flex flex-col lg:flex-row lg:gap-x-4'>
        <div :style='posterDetailStyle'>
          <h1 class='poster-title'>
            {{ selectedPoster.title }}
          </h1>
          <h3 class='poster-tag'>
            {{ tagsString }}
          </h3>
          <h3>
            {{ posterAuthorsListString }}
          </h3>
          <h3 class='mb-8'>
            {{ posterAuthorsAffiliationString }}
          </h3>
          <div v-if='selectedPoster.description'>
            <h2 class='text-lg font-semibold mb-1'>Description</h2>
            <h3 class='whitespace-pre-line'>
              {{ selectedPoster.description }}
            </h3>
          </div>
        </div>
        <div v-if='hasSimilarPosters' class='lg:flex-grow my-8 lg:my-0 pl-2 lg:pl-0'>
          <div class='poster-title'>Similar Posters</div>
        </div>
      </div>
      <div class='flex flex-row items-center gap-x-2 mt-8'>
        <star-rating v-model='myScore' :star-size='24' :animate='true' :padding='1' :glow='1' @rating-selected='setScore' />
        <div class='text-sm text-gray-500' v-if='showingEventId === 19'>(포상평가에 반영됩니다)</div>
      </div>
      <comments class='mt-8' :comments='selectedPosterComments' />
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'
import Comments               from '@/components/comments/Comments.vue'
import PDFContent             from '@/components/contents/PDFContent.vue'
import PosterAbstractContent  from '@/components/contents/PosterAbstractContent.vue'
import VodContentVimeo        from '@/components/contents/VodContentVimeo.vue'
import PosterAudioPlayer      from '@/components/posters/PosterAudioPlayer.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { ArrowNarrowLeftIcon } from 'vue-tabler-icons'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'Poster',
  components: {
    StarRating,
    ArrowNarrowLeftIcon,
    PDFContent,
    VodContentVimeo,
    PosterAudioPlayer,
    PosterAbstractContent,
    Comments,
    ContentBackgroundCover,
  },
  data () {
    return {
      showContent: 'poster',
      myScore: 0,
      defaultPosterVolume: 0.8,
    }
  },
  watch: {
    'selectedPoster.myScore': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setMyRating(newVal)
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('posters', [
      'selectedPoster'
    ]),
    ...mapGetters('posters', [
      'selectedPosterComments',
    ]),
    ...mapGetters('events', [
      'showingEventId',
    ]),
    tagsString () {
      if (this.selectedPoster && this.selectedPoster.conferenceTags) {
        let tagList =  this.selectedPoster.conferenceTags.map(tag => {
          return tag.list
        }).flat()
        return tagList.map(tag => {
          return `#${tag}`
        }).join(' ')
      } else {
        return ''
      }
    },
    posterDetailStyle () {
      return (this.hasSimilarPosters) ? `width: 66%` : 'width: 100%;' 
    },
    posterAuthorsListString () {
      return (this.selectedPoster.authors) ? this.selectedPoster.authors.list.join(', ') : ''
    },
    posterAuthorsAffiliationString () {
      return (this.selectedPoster.authors) ? this.selectedPoster.authors.affiliation : ''
    },
    hasSimilarPosters () {
      return false
    },
    isPosterTypePdf () {
      return this.selectedPoster.posterType === 'pdf'
    },
    hasAbstractFile () {
      return !isEmpty(this.selectedPoster.abstractFile)
    },
    showPosterContent () {
      return this.showContent === 'poster'
    },
    showAbstractContent () {
      return this.showContent === 'abstract'
    },
    hasPosterAndAbstractFile () {
      return !isEmpty(this.selectedPoster.posterFile) && 
             this.hasAbstractFile
    },
    hasAudioFile () {
      return this.selectedPoster.audioFileUrl
    },
  },
  methods: {
    ...mapActions('posters', [
      'getSinglePoster',
      'updatePosterScore',
    ]),
    ...mapActions('comments', [
      'updateCommentInstance',
    ]),
    goBack () {
      this.$router.go(-1)
    },
    selectedTabClass (tab) {
      if (this.showContent === tab) {
        return 'text-sm uppercase py-1 px-2 border border-gray-200 rounded shadow-md font-semibold'
      } else {
        return 'text-sm uppercase py-1 px-2 border border-transparent text-gray-100 hover:bg-gray-600 rounded'
      }
    },
    setMyRating (newVal) {
      this.myScore = newVal
    },
    setScore (score) {
      this.updatePosterScore(score)
    },
  },
  mounted () {
    this.getSinglePoster(this.$route.query.poster_id)
    this.updateCommentInstance({instanceType: 'Poster', instanceId: parseInt(this.$route.query.poster_id)})
  },
}
</script>

<style type='scss' scoped>
.poster-title {
  @apply text-gray-700 text-2xl font-semibold mb-4;
}

.poster-tag {
  color: #529dad;
  @apply mb-4 font-medium;
}
</style>
