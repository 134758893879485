<template>
  <div>
    <audio id='audio-player'
      :src='selectedPoster.audioFileUrl'
      @playing='updateIsAudioPlayerPlaying' @pause='updateIsAudioPlayerPlaying'>    
    </audio>
    <button @click='toggleAudioPlayer'
      class='pl-1 pr-2 hover:pl-0 rounded text-white border border-transparent hover:border-gray-200 flex flex-row justify-center items-center text-normal'>
      <component :is='audioPlayerToggleButtonIcon' class='h-4' />
      {{ audioPlayerToggleButtonText }}
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PlayerPlayIcon, PlayerPauseIcon } from 'vue-tabler-icons'

export default {
  name: 'PosterAudioPlayer',
  components: {
    PlayerPlayIcon,
    PlayerPauseIcon,
  },
  data () {
    return {
      isAudioPlayerPlaying: false,
    }
  },
  computed: {
    ...mapState('posters', [
      'selectedPoster'
    ]),
    audioPlayerToggleButtonIcon () {
      return this.isAudioPlayerPlaying ? 'player-pause-icon' : 'player-play-icon'
    },
    audioPlayerToggleButtonText () {
      return this.isAudioPlayerPlaying ? 'Pause Audio' : 'Play Audio'
    },
  },
  methods: {
    updateIsAudioPlayerPlaying (event) {
      this.isAudioPlayerPlaying = !event.target.paused
    },
    toggleAudioPlayer () {
      if (this.isAudioPlayerPlaying) {
        document.getElementById('audio-player').pause()
      } else {
        document.getElementById('audio-player').play()
      }
    },
  },
}
</script>
