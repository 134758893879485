<template>
  <div>
    <pdf
      v-if='selectedPosterFilePath'
      class='layout-container w-full lg:w-3/4'
      :src='selectedPosterFilePath'
      :page='currentPage'
      :key='componentKey'
      @num-pages='pageCount = $event'
      @page-loaded='pageLoaded($event)'
      @loaded='pdfLoaded'
      @error='error'>
    </pdf>
    <button v-if='hasMultiplePages'
            class='absolute z-10 text-gray-400 hover:text-gray-500 pdf-previous-page-button'
            @click='previousPage'>
      <arrow-circle-left-icon size='3x'/>
    </button>
    <button v-if='hasMultiplePages'
            class='absolute z-10 text-gray-400 hover:text-gray-500 pdf-next-page-button'
            @click='nextPage'>
      <arrow-circle-right-icon size='3x'/>
    </button>
    <div v-if='hasMultiplePages'
         class='text-xs absolute -bottom-8 lg:bottom-2 lg:text-gray-100'
         style='left: 49%'>
      {{currentPage}}/{{pageCount}}
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import { ArrowCircleLeftIcon, ArrowCircleRightIcon } from '@vue-hero-icons/outline'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'PDFContent',
  components: {
    ArrowCircleLeftIcon,
    ArrowCircleRightIcon,
    pdf
  },
  data() {
    return {
      currentPage: 1,
      pageCount: 0,
      componentKey: 0,
    }
  },
  computed: {
    ...mapState('posters', [
      'selectedPoster'
    ]),
    selectedPosterFilePath () {
      return (this.selectedPoster.posterFile) ? this.selectedPoster.posterFile.path : ''
    },
    hasMultiplePages () {
      return this.pageCount > 1
    }
  },
  methods: {
    ...mapActions('posters', [
      'resetSelectedPoster',
    ]),
    nextPage () {
      if (this.currentPage < this.pageCount) {
        this.currentPage += 1
      }
    },
    previousPage () {
      if (this.currentPage > 1) {
        this.currentPage -= 1
      }
    },
    pageLoaded (event) {
      console.log('pageLoaded')
      this.currentPage = event
    },
    pdfLoaded () {
      console.log('pdfLoaded')
    },
    error (error) {
      console.error(error)
      this.componentKey += 1 // should reload the component
      setTimeout(() => location.reload(), 200) // TODO (Hana: 2022-03-11): be more spec ific about which error to reload
    }
  },
  destroyed () {
    this.resetSelectedPoster()
  }
}
</script>

<style type='scss' scoped>
.pdf-previous-page-button {
  @apply left-2 -bottom-14
}

.pdf-next-page-button {
  @apply right-2 -bottom-14
}

@media (min-width: 1024px) { 
  .pdf-previous-page-button {
    @apply top-12 left-4 inset-y-0
  }
  .pdf-next-page-button {
    bottom: unset;
    @apply top-12 inset-y-0 right-4
  }
}

</style>
